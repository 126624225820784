.back-btn-modal{
    background-color: var(--background-red);
    padding: 8px 50px;
    border: none;
    border-radius: 3px;
    color: var(--white-color);
    float: left;
    cursor: pointer;
    outline: none;
    font-weight: 500;
}

.info-container-left{
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 20px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.comfirm-header{
    font-family: var(--font-family);
    font-weight: 500;
}

.info-container-right{
    width: 100%;
    margin-left: 5%;
    border: 1px solid #e4e4e4;
    padding: 20px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.info-modal-container {
    display: flex;
    width: 90%;
    margin-left: 5%;
    font-family: var(--font-family);
    font-weight: 300;
}

.row{
    display: flex;
    justify-content: space-between;
    border: 1px solid #e4e4e4;
    padding: 10px;

    margin-top: 5px;
}

.info{
    font-weight: bold;
}

.modal-buttons {
    margin-top: -30px;
    margin-bottom: 10%
}


@media (max-width: 480px) {
    .info-modal-container {
        flex-direction: column;
    }

    .row {
        flex-direction: column;
    }

    .info-container-right{
        margin-left: 0;
        margin-top: 10%;
    }

    .modal-buttons{
        display: flex;
        margin-top: -10px;
    }
  }
