.button-container{
    margin-top: 5%;
}

.next-btn {
    background-color: var(--orange-color) !important;
    padding: 8px 50px !important;
    border: none !important;
    border-radius: 3px !important;
    color: var(--white-color) !important;
    float: right !important;
    cursor: pointer !important;
    outline: none !important;
    font-weight: bold !important;
    font-family: var(--font-family) !important;
}

.back-btn {
    background-color: var(--white-color);
    padding: 8px 50px;
    border: none;
    border-radius: 3px;
    color: var(--text-color-black);
    float: left;
    cursor: pointer;
    outline: none;
    font-weight: 500;
    font-family: var(--font-family);
}

.back-btn:hover{
    background-color: #f6f8f9 !important;
}

.next-btn:hover{
    background-color: #d95f29 !important;
}