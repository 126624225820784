.close-container {
    width: 100%;
}

.close-message-container{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
}

.success-icon-on-close{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20% !important;
}