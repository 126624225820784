.client-info-container{
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    font-family: var(--font-family);
}

.client-info-form{
    display: flex;
    flex-direction: column;
    padding: 12% 25% 10% 25%;
    width: 100%;
}

.input-text{
    border: none;
    margin-top: 33px;
    padding: 11px;
    border-radius: 5px;
    outline: none;
    font-family: var(--font-family);
}

.input-text:focus{
    border: 1px solid var(--onfocus-color)
}

.input-text::placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.personal-info{
    display: flex;
    flex-direction: column;
}

@media (max-width: 480px) {
    .client-info-form{
        padding: 2%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.client-info-form{
        padding: 2%;
    }
    
}