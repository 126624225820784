@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap);
:root {
  --white-color: #ffffff;
  --text-color-white: #f9f9f9d9;
  --text-color-black: #000000;

  --orange-color: #ef7b10;
  --background-color: #F2F9FA;
  --background-color-container: #e4f7fd;
  --placeholder-color: #96b2b4;
  --onfocus-color: #6cb5e0;
  --background-red: #ef4545;

  --font-family: "Poppins", sans-serif;
  --box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-family: var(--font-family);
  background-color: #F2F9FA;
  background-color: var(--background-color);
}

a {
  color: #ef7b10;
  color: var(--orange-color);
  text-decoration: none;
}

* {
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  margin: 0;
}
.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    color: #4e4e4e;
    padding: 0.5rem 0.25rem;
}

.checkbox > input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #4e4e4e;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ef7b10;
    cursor: pointer;
  }

.checkbox > input:checked {
    background-color: #f1f1f1;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: #ef7b10;
    color: var(--orange-color);
    font-size: 22px;
    position: absolute;
    left: .3rem;
    top: .4rem;
}

.checkbox > input:active {
    border: 2px solid #4e4e4e;
}

.header-section{
    width: 100%;
    height: 100vh;
    display: flex;
}

.left-side{
    background-color: var(--orange-color);
    width: 100%;
    height: 100vh;
}

.header-machine-image-containter{
    width: 100%;
    height: 100vh;
    background-color: var(--white-color);
}

.image-container{
    width: 100%;
    margin-top: 20%;
}

.call-to-action{
    width: 70%;
    margin-left: 10%;
}

.call-to-action-text {
    color: var(--white-color);
    margin-bottom: 2rem;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 300;
}

.start-btn{
    border: none;
    padding: 10px 40px;
    border-radius: 5px;
    margin-left: 30%;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.start-btn:hover{
    background-color: var(--background-color-container);
    color: #00a8a9;
}

.logo-container{
    width: 30%;
    margin-left: 30%;
    margin-top: 12%;
}

.logo-container img{
    width: 100%;
}

@media (max-width: 480px) {
    .header-section{
        flex-direction: column;
    }

    .call-to-action{
        margin-top: 25%;
        margin-left: 15%;
    }

    .start-btn{
        margin-left: 10%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.call-to-action {
        margin-top: 65%;
        width: 80%;
    }

    .image-container {
        margin-top: 50%;
    }
}

.video-section-container {
    width: 70%;
    margin: 0 auto;
    margin-top: 10%;
    font-family: var(--font-family);
}

.video-container {
    /* width: 30%; */
    /* height: 70%; */
}

.video-content{
    /* width: 100%;
    height: 70%; */
}

.video-container:not(:first-child){
    /* margin-left: 4.5%; */
}

@media (max-width: 480px) {
    .video-section-container{
        flex-direction: column;
        width: 100%;
    }

    .video-container {
        width: 95%;
    }

    .video-container:not(:first-child){
        margin: 2%;
    }

    .video-container:first-child{
        margin: 2%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.video-container {
        width: 45%;
    }
}
.button-container{
    margin-top: 5%;
}

.next-btn {
    background-color: var(--orange-color) !important;
    padding: 8px 50px !important;
    border: none !important;
    border-radius: 3px !important;
    color: var(--white-color) !important;
    float: right !important;
    cursor: pointer !important;
    outline: none !important;
    font-weight: bold !important;
    font-family: var(--font-family) !important;
}

.back-btn {
    background-color: var(--white-color);
    padding: 8px 50px;
    border: none;
    border-radius: 3px;
    color: var(--text-color-black);
    float: left;
    cursor: pointer;
    outline: none;
    font-weight: 500;
    font-family: var(--font-family);
}

.back-btn:hover{
    background-color: #f6f8f9 !important;
}

.next-btn:hover{
    background-color: #d95f29 !important;
}
.container-request {
    width: 100%;
    display: flex;
    margin-top: 10%;
    background-color: var(--background-color-container);
    font-family: var(--font-family);
}

.request-left-side {
    background-color: var(--orange-color);
    width: 60%;
}
.request-left-side .logo-container{margin-top:15%;    margin-left: 34%;}
.left-text {
    padding-top: 6%;
    padding-bottom: 50%;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align:center;
    color: var(--text-color-white);
    font-size: 25px;
}

.left-text p:not(:first-child){
    margin-top: -30px;
}

.conset-content {
    padding: 10% 5% 5% 5%;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
}

.mgn-top{
    margin-top: 5%;
}

.text-content{
 text-align: justify;
 font-weight: 300;
}

.conset-check {
    display: flex;
    color: var(--orange-color);
    margin-top: 5%
}

.check-box-input {
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.error-message {
    margin-top: 13px;
    margin-bottom: -15px;
    margin-left: 15px;
    color: var(--orange-color);
}

.error-margins{
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 15px;
    color: var(--orange-color);
}

@media (max-width: 480px) {
    .container-request {
        flex-direction: column;
    }

    .left-text {
        margin: 20% 5%;
    }

    .request-left-side {
        width: 100%;
    }

    .text-content{
        width: 100%;
    }

    .conset-check p{
        font-size: 12px;
        margin-top: 2px;
    }

    .conset-content {
        width: 100%;
    }

    .request-left-side {
        width: 100%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.request-left-side {
        width: 80%;
    }

}

.client-info-container{
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    font-family: var(--font-family);
}

.client-info-form{
    display: flex;
    flex-direction: column;
    padding: 12% 25% 10% 25%;
    width: 100%;
}

.input-text{
    border: none;
    margin-top: 33px;
    padding: 11px;
    border-radius: 5px;
    outline: none;
    font-family: var(--font-family);
}

.input-text:focus{
    border: 1px solid var(--onfocus-color)
}

.input-text::-webkit-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-text:-ms-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-text::-ms-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-text::placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.personal-info{
    display: flex;
    flex-direction: column;
}

@media (max-width: 480px) {
    .client-info-form{
        padding: 2%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.client-info-form{
        padding: 2%;
    }
    
}
.back-btn-modal{
    background-color: var(--background-red);
    padding: 8px 50px;
    border: none;
    border-radius: 3px;
    color: var(--white-color);
    float: left;
    cursor: pointer;
    outline: none;
    font-weight: 500;
}

.info-container-left{
    width: 100%;
    border: 1px solid #e4e4e4;
    padding: 20px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.comfirm-header{
    font-family: var(--font-family);
    font-weight: 500;
}

.info-container-right{
    width: 100%;
    margin-left: 5%;
    border: 1px solid #e4e4e4;
    padding: 20px;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}

.info-modal-container {
    display: flex;
    width: 90%;
    margin-left: 5%;
    font-family: var(--font-family);
    font-weight: 300;
}

.row{
    display: flex;
    justify-content: space-between;
    border: 1px solid #e4e4e4;
    padding: 10px;

    margin-top: 5px;
}

.info{
    font-weight: bold;
}

.modal-buttons {
    margin-top: -30px;
    margin-bottom: 10%
}


@media (max-width: 480px) {
    .info-modal-container {
        flex-direction: column;
    }

    .row {
        flex-direction: column;
    }

    .info-container-right{
        margin-left: 0;
        margin-top: 10%;
    }

    .modal-buttons{
        display: flex;
        margin-top: -10px;
    }
  }

.comfirmation-message{
    width: 30% !important;
}

.close-modal-btn {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 10px 40px !important;
    margin-bottom: 2rem !important;
    color: var(--white-color) !important;
}

.btn-color-green{
    background-color: #21ba45 !important;
}

.btn-color-orange{
    background-color: var(--orange-color) !important;
}

.btn-color-red{
    background-color: #ff0000d9 !important;
}

.action-container{
    border: none !important;
    background-color: var(--white-color) !important;
}

.success-icon {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20% !important;
    margin-bottom: 20% !important;
    color: #ff0000d9 !important;
}

.error-title {
    text-align: center !important;
    margin-top: 2rem !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    color: #ff0000d9 !important;
}

.paragraph-message {
    text-align: center;
    margin-top: 2rem;
    width: 70% !important;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-family);
    font-weight: 300 !important;
    font-size: 16px !important;
}

.success-title{
    text-align: center !important;
    margin-top: 2rem !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    color: #21ba45;
}

@media (max-width: 480px) {
    .comfirmation-message{
        width: 80% !important;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
    .comfirmation-message{
        width: 40% !important;
    }
    
}
.do-not-have-machine{
    display: flex;
    flex-direction: column !important;
}
.have-the-machine-container{
    display: flex;
    flex-direction: row !important;
    margin-top: 35px;
}
.have-the-machine-container label{
    margin-left: 5px;
}

#yes {
    margin-left: 1rem;
    margin-top: 4px;
}

#no {
    margin-left: 1rem;
    margin-top: 4px;
}

.input-area{
    border: none;
    margin-top: 33px;
    padding: 20px 10px;
    height: 100px;
    border-radius: 5px;
    outline: none;
    font-family: var(--font-family);
}

.input-area::-webkit-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-area:-ms-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-area::-ms-input-placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-area::placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-area:focus{
    border: 1px solid var(--onfocus-color)
}

.serial-number-example{
    width: 100%;
    height: 100%;
}

.open-popup{
    background-color: var(--white-color) !important;
    margin-top: 30px !important;
    margin-right: 10px !important;
}

.btn-text{
    display: flex;
    justify-content: space-between;
}

.p-space {
    margin-top: 10px;
    margin-bottom: 30px;
}
.close-container {
    width: 100%;
}

.close-message-container{
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2.5rem;
}

.success-icon-on-close{
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20% !important;
}
