.video-section-container {
    width: 70%;
    margin: 0 auto;
    margin-top: 10%;
    font-family: var(--font-family);
}

.video-container {
    /* width: 30%; */
    /* height: 70%; */
}

.video-content{
    /* width: 100%;
    height: 70%; */
}

.video-container:not(:first-child){
    /* margin-left: 4.5%; */
}

@media (max-width: 480px) {
    .video-section-container{
        flex-direction: column;
        width: 100%;
    }

    .video-container {
        width: 95%;
    }

    .video-container:not(:first-child){
        margin: 2%;
    }

    .video-container:first-child{
        margin: 2%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.video-container {
        width: 45%;
    }
}