.comfirmation-message{
    width: 30% !important;
}

.close-modal-btn {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 10px 40px !important;
    margin-bottom: 2rem !important;
    color: var(--white-color) !important;
}

.btn-color-green{
    background-color: #21ba45 !important;
}

.btn-color-orange{
    background-color: var(--orange-color) !important;
}

.btn-color-red{
    background-color: #ff0000d9 !important;
}

.action-container{
    border: none !important;
    background-color: var(--white-color) !important;
}

.success-icon {
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20% !important;
    margin-bottom: 20% !important;
    color: #ff0000d9 !important;
}

.error-title {
    text-align: center !important;
    margin-top: 2rem !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    color: #ff0000d9 !important;
}

.paragraph-message {
    text-align: center;
    margin-top: 2rem;
    width: 70% !important;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--font-family);
    font-weight: 300 !important;
    font-size: 16px !important;
}

.success-title{
    text-align: center !important;
    margin-top: 2rem !important;
    font-family: var(--font-family) !important;
    font-size: 16px !important;
    color: #21ba45;
}

@media (max-width: 480px) {
    .comfirmation-message{
        width: 80% !important;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
    .comfirmation-message{
        width: 40% !important;
    }
    
}