.header-section{
    width: 100%;
    height: 100vh;
    display: flex;
}

.left-side{
    background-color: var(--orange-color);
    width: 100%;
    height: 100vh;
}

.header-machine-image-containter{
    width: 100%;
    height: 100vh;
    background-color: var(--white-color);
}

.image-container{
    width: 100%;
    margin-top: 20%;
}

.call-to-action{
    width: 70%;
    margin-left: 10%;
}

.call-to-action-text {
    color: var(--white-color);
    margin-bottom: 2rem;
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 300;
}

.start-btn{
    border: none;
    padding: 10px 40px;
    border-radius: 5px;
    margin-left: 30%;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}

.start-btn:hover{
    background-color: var(--background-color-container);
    color: #00a8a9;
}

.logo-container{
    width: 30%;
    margin-left: 30%;
    margin-top: 12%;
}

.logo-container img{
    width: 100%;
}

@media (max-width: 480px) {
    .header-section{
        flex-direction: column;
    }

    .call-to-action{
        margin-top: 25%;
        margin-left: 15%;
    }

    .start-btn{
        margin-left: 10%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.call-to-action {
        margin-top: 65%;
        width: 80%;
    }

    .image-container {
        margin-top: 50%;
    }
}
