.container-request {
    width: 100%;
    display: flex;
    margin-top: 10%;
    background-color: var(--background-color-container);
    font-family: var(--font-family);
}

.request-left-side {
    background-color: var(--orange-color);
    width: 60%;
}
.request-left-side .logo-container{margin-top:15%;    margin-left: 34%;}
.left-text {
    padding-top: 6%;
    padding-bottom: 50%;
    margin-left: 2rem;
    margin-right: 2rem;
    text-align:center;
    color: var(--text-color-white);
    font-size: 25px;
}

.left-text p:not(:first-child){
    margin-top: -30px;
}

.conset-content {
    padding: 10% 5% 5% 5%;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
}

.mgn-top{
    margin-top: 5%;
}

.text-content{
 text-align: justify;
 font-weight: 300;
}

.conset-check {
    display: flex;
    color: var(--orange-color);
    margin-top: 5%
}

.check-box-input {
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.error-message {
    margin-top: 13px;
    margin-bottom: -15px;
    margin-left: 15px;
    color: var(--orange-color);
}

.error-margins{
    margin-top: 0;
    margin-bottom: 5px;
    margin-left: 15px;
    color: var(--orange-color);
}

@media (max-width: 480px) {
    .container-request {
        flex-direction: column;
    }

    .left-text {
        margin: 20% 5%;
    }

    .request-left-side {
        width: 100%;
    }

    .text-content{
        width: 100%;
    }

    .conset-check p{
        font-size: 12px;
        margin-top: 2px;
    }

    .conset-content {
        width: 100%;
    }

    .request-left-side {
        width: 100%;
    }
  }

@media (max-width: 768px) and (min-width: 480px) {
	.request-left-side {
        width: 80%;
    }

}
