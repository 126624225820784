@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');

:root {
  --white-color: #ffffff;
  --text-color-white: #f9f9f9d9;
  --text-color-black: #000000;

  --orange-color: #ef7b10;
  --background-color: #F2F9FA;
  --background-color-container: #e4f7fd;
  --placeholder-color: #96b2b4;
  --onfocus-color: #6cb5e0;
  --background-red: #ef4545;

  --font-family: "Poppins", sans-serif;
  --box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--background-color);
}

a {
  color: var(--orange-color);
  text-decoration: none;
}

* {
  box-sizing: border-box;
  color: inherit;
  font: inherit;
  margin: 0;
}
.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox > span {
    color: #4e4e4e;
    padding: 0.5rem 0.25rem;
}

.checkbox > input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #4e4e4e;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ef7b10;
    cursor: pointer;
  }

.checkbox > input:checked {
    background-color: #f1f1f1;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    text-align: center;
    color: var(--orange-color);
    font-size: 22px;
    position: absolute;
    left: .3rem;
    top: .4rem;
}

.checkbox > input:active {
    border: 2px solid #4e4e4e;
}
