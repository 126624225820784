.do-not-have-machine{
    display: flex;
    flex-direction: column !important;
}
.have-the-machine-container{
    display: flex;
    flex-direction: row !important;
    margin-top: 35px;
}
.have-the-machine-container label{
    margin-left: 5px;
}

#yes {
    margin-left: 1rem;
    margin-top: 4px;
}

#no {
    margin-left: 1rem;
    margin-top: 4px;
}

.input-area{
    border: none;
    margin-top: 33px;
    padding: 20px 10px;
    height: 100px;
    border-radius: 5px;
    outline: none;
    font-family: var(--font-family);
}

.input-area::placeholder{
    color: var(--placeholder-color);
    font-family: var(--font-family);
}

.input-area:focus{
    border: 1px solid var(--onfocus-color)
}

.serial-number-example{
    width: 100%;
    height: 100%;
}

.open-popup{
    background-color: var(--white-color) !important;
    margin-top: 30px !important;
    margin-right: 10px !important;
}

.btn-text{
    display: flex;
    justify-content: space-between;
}
